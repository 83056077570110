import axios from 'axios';

const api = axios.create({
  baseURL: 'https://backend.chashmaplanet.com/api/'
  // baseURL :"http://192.168.29.121:7100/api/"
});
//  api.defaults.withCredentials = true;

// api.interceptors.response.use(function (response) {
//     return response.dajta;
// }, function (error) {
//     return Promise.reject(error);
// });

export default api;
