import React from 'react';
import Banner from '../components/banner';
import NavBar from '../components/navbar';
import SideBar from '../components/sidebar';
import Footer from '../components/footer';

const Home = (props) => {
    return (
      <div>
        <NavBar />
        <Banner />
        <div className="wrapper d-flex align-items-stretch">
          <SideBar topService={props.location.state?.service} />
        </div>
        <Footer />
      </div>
    );
  }

export default Home;
