/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Row,
  Spinner,
} from 'reactstrap';

import { useHistory } from 'react-router';
import { Tooltip } from '@material-ui/core';
import TextEditorTwo from '../../../../shared/components/text-editor/TextEditor';
import validate from './validate';
import api from '../../../../api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  'black',
  'brown',
  'blue',
  'grey',
  'red',
  'orange',
  'yellow',
  'pink',
  'green',
  'white',
  'purple',
  'tortoise',
  'gunmetal',
  'golden',
  'silver',
  'rosegold',
  'maroon',
  'transparent',
];

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const HorizontalForm = () => {
  const [color, setcolor] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedColors = typeof value === 'string' ? value.split(',') : value;
    setcolor(selectedColors);

    // Clear the color error if a color is selected
    if (selectedColors.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        color: '',
      }));
    }
  };

  const [name, setname] = useState('');
  const [type, settype] = useState('');
  const [price, setprice] = useState('');
  const [shape, setshape] = useState('');
  const [brand, setbrand] = useState('');
  const [gender, setgender] = useState('');
  const [discount_price, setdiscount_price] = useState('');
  const [technical_infornation, settechnical_infornation] = useState('');
  const [material, setmaterial] = useState('');
  const [category, setcategory] = useState('');
  const [disposables, setdisposables] = useState('');
  const [imgCollection, setimgCollection] = useState('');

  const [colorArr, setcolorArr] = useState([]);
  const [isTrending, setisTrending] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Errors, setErrors] = useState('');
  const [size, setsize] = useState('');
  const [quantity, setquantity] = useState('');
  const [productType, setProductType] = useState('');
  const [productId, setProductId] = useState('');
  const [accessoriesCategory, setAccessoriesCategory] = useState('');
  const [uploadedImages, setUploadedImages] = useState({});
  const histroy = useHistory();
  async function AddLens(e) {
    e.preventDefault();
    console.log('hhhhhhhhhhhhh');
    
    const errors = {};
    if (!color || color.length === 0) {
      errors.color = 'Please select at least one color';
    }
    if (!name) {
      errors.name = 'Name is required';
    }
    if (!quantity) {
      errors.quantity = 'Quantity is required';
    }
    if (!price) {
      errors.price = 'Price is required';
    }
    if (!discount_price) {
      errors.discount_price = 'Discount price is required';
    }
    if (!material) {
      errors.material = 'Material is required';
    }
    if (!technical_infornation) {
      errors.technical_infornation = 'Technical infornation is required';
    }
    if (!brand) {
      errors.brand = 'Brand is required';
    }
    if (!productId) {
      errors.productId = 'ProductId is required';
    }
    if (!productType) {
      errors.productType = 'Select Product Type';
    }
    if (!category) {
      errors.category = 'Select category ';
    }
    if (!type) {
      errors.type = 'Select type ';
    }
    if (!shape) {
      errors.shape = 'Select shape ';
    }
    if (!size) {
      errors.size = 'Select size ';
    }
    if (!gender) {
      errors.gender = 'Select gender ';
    }
    if (!imgCollection) {
      errors.imgCollection = 'Please choose image ';
    }


    color.forEach(c => {
      for (let i = 1; i < 4; i++) {
        
        if (!colorArr[0]?.images[i]) {
          errors[c] = 'Please choose images for all positions';
          break;
        }
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return; 
    }
    const data = {
      name,
      type,
      price,
      shape,
      brand,
      gender,
      discount_price,
      technical_infornation,
      material,
      category,
      imgCollection,
      size,
      quantity,
      disposables,
      productType,
      productId,
    };

    try {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;

        return config;
      });

      const newColorArr = colorArr.filter((e) => {
        if (color.includes(e.color)) {
          return e;
        }
      });
      

      const colorArrayAfterModification = newColorArr.map((e) => {
        const sortData = e.images.sort((a, b) => {
          const keyA = a.postion;
          const keyB = b.postion;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        return { color: e.color, images: sortData.map(r => r.image) };
      });

      setLoading(true);

      const result = await api.post('admin/add/lens', {
        name,
        discount_price,
        type,
        size,
        quantity,
        price,
        shape,
        brand,
        gender,
        technical_infornation,
        material,
        category,
        imgCollection,
        isTrending,
        color: colorArrayAfterModification,
        disposables: category === 'Contact Lens' ? disposables : '',
        productType,
        productId,
        accessoriesCategory: category === 'Accessories' ? accessoriesCategory : '',
      });
      if (result.data.success) {
        histroy.push('/tables/services');
      } else {
        setLoading(false);
        toast.error('Please Recheck You Form !!!!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error('Server Error !!!! Please Try Later', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  function RemoveImage(event) {
    console.log(event.target.getAttribute('data-color'));
    const modifiedColorArr = colorArr.map((e) => {
      if (e.color == event.target.getAttribute('data-color')) {
        const newImagesModified = e.images.filter(
          a => a.image != event.target.getAttribute('data-image'),
        );
        return { color: e.color, images: newImagesModified };
      }
      return e;
    });
    setcolorArr(modifiedColorArr);
  }

  async function CoverPic(e) {
    setLoading(true);
    const formData = new FormData();
    for (const key of Object.keys(e.target.files)) {
      formData.append('images', e.target.files[key]);
    }
    const data = await api.post('admin/addPic', formData);
    setimgCollection(data.data.data[0]);
    setLoading(false);
  }

  async function UploadFileAndGetUrl(e, postion) {
    console.log(e.target.files,'filessssss-------------');
    
    console.log(postion);
    setLoading(true);

    sessionStorage.setItem('color-name', e.target.name);
    const formData = new FormData();

    for (const key of Object.keys(e.target.files)) {
      formData.append('images', e.target.files[key]);
    }
    const data = await api.post('admin/addPic', formData);

    const checkifexist = colorArr.filter((e) => {
      if (e.color == sessionStorage.getItem('color-name')) {
        return e;
      }
    });
    if (checkifexist.length > 0) {
      const newArrAfterUpdate = colorArr.map((e) => {
        if (e.color == sessionStorage.getItem('color-name')) {
          e.images = [
            ...e.images,
            { postion, image: data.data.data[0] },
          ];
        }
        return e;
      });
      setcolorArr(newArrAfterUpdate);
    } else {
      setcolorArr(pre => [
        ...pre,
        {
          color: sessionStorage.getItem('color-name'),
          images: [{ postion, image: data.data.data[0] }],
        },
      ]);
      // setErrors("")
    }

    setLoading(false);
  }
  console.log(colorArr, colorArr.length);


  return (
    <Row
      style={{
        width: '100%',
        height: '60vh',
        marginLeft: 1,
        flex: 1,
        justifyContent: 'center',
      }}
    >
      {!Loading ? (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Add New Products</h5>
              </div>
              <form className="form form--horizontal" onSubmit={AddLens}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of The Lens"
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                          if (Errors && Errors.name) {
                            setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
                          }
                        }}
                        name="name"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Quantity</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Quantity Of The Lens"
                        type="number"
                        value={quantity}
                        onChange={(e) => {
                          setquantity(e.target.value);
                          if (Errors && Errors.quantity) {
                            setErrors((prevErrors) => ({ ...prevErrors, quantity: '' }));
                          }
                        }}
                        name="quantity"
                      />
                      {Errors ? (
                        Errors.quantity ? (
                          <span className="form__form-group-error">
                            {Errors.quantity}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Price</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Price Of The Lens"
                        type="number"
                        value={price}
                        onChange={e => {
                          setprice(e.target.value)
                          if (Errors && Errors.price) {
                            setErrors((prevErrors) => ({ ...prevErrors, price: '' }));
                          }
                        }}
                        name="price"
                      />
                      {Errors ? (
                        Errors.price ? (
                          <span className="form__form-group-error">
                            {Errors.price}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Discount Price</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Discount Price Of The Lens"
                        type="number"
                        value={discount_price}
                        onChange={e => {
                          setdiscount_price(e.target.value)
                          if (Errors && Errors.discount_price) {
                            setErrors((prevErrors) => ({ ...prevErrors, discount_price: '' }));
                          }
                        }}
                        name="discount_price"
                      />
                      {Errors ? (
                        Errors.discount_price ? (
                          <span className="form__form-group-error">
                            {Errors.discount_price}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Material</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Material Of The Lens"
                        value={material}
                        onChange={e => {
                          setmaterial(e.target.value)
                          if (Errors && Errors.material) {
                            setErrors((prevErrors) => ({ ...prevErrors, material: '' }));
                          }
                        }}
                        name="Material"
                      />
                      {Errors ? (
                        Errors.material ? (
                          <span className="form__form-group-error">
                            {Errors.material}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Technical Infornation
                  </span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <TextEditorTwo
                        data={technical_infornation}
                        onChange={(val) => {
                          settechnical_infornation(val);

                          const strippedValue = val.replace(/<[^>]*>/g, '').trim();

                          if (strippedValue !== '') {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              technical_infornation: '',
                            }));
                          }
                        }}
                        onBlur={() => {
                          const strippedValue = technical_infornation.replace(/<[^>]*>/g, '').trim();
                          if (strippedValue === '') {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              technical_infornation: 'Technical Information is required',
                            }));
                          }
                        }}
                        name="technical_infornation"
                        isButtonShow={false}
                      />
                      {Errors ? (

                        Errors.technical_infornation ? (
                          <span className="form__form-group-error">
                            {Errors.technical_infornation}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Brand</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter brand Of The Lens"
                        value={brand}
                        onChange={e => {
                          setbrand(e.target.value)
                          if (Errors && Errors.brand) {
                            setErrors((prevErrors) => ({ ...prevErrors, brand: '' }));
                          }
                        }}
                        name="brand"
                      />
                      {Errors ? (
                        Errors.brand ? (
                          <span className="form__form-group-error">
                            {Errors.brand}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Product ID</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Product ID"
                        value={productId}
                        onChange={e => {
                          setProductId(e.target.value)
                          if (Errors && Errors.productId) {
                            setErrors((prevErrors) => ({ ...prevErrors, productId: '' }));
                          }
                        }}
                        name="productId"
                      />
                      {Errors ? (
                        Errors.productId ? (
                          <span className="form__form-group-error">
                            {Errors.productId}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Product Type</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          setProductType(e.target.value)
                          if (Errors && Errors.productType) {
                            setErrors((prevErrors) => ({ ...prevErrors, productType: '' }));
                          }
                        }}
                        value={productType}
                        name='productType'
                        aria-label="Default select example"
                      >
                        <option selected>Select The Product Type</option>
                        <option value="Inhouse">Inhouse</option>
                        <option value="International">International</option>
                      </select>
                      {Errors ? (
                        Errors.productType ? (
                          <span className="form__form-group-error">
                            {Errors.productType}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Product Category</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          setcategory(e.target.value)
                          if (Errors && Errors.category) {
                            setErrors((prevErrors) => ({ ...prevErrors, category: '' }));
                          }
                        }}
                        value={category}
                        aria-label="Default select example"
                      >
                        <option selected>Select The Product Category</option>
                        <option value="Eyeglasses">Eyeglasses</option>
                        <option value="Sunglasses">Sunglasses</option>
                        <option value="Computer Glasses">
                          Computer Glasses
                        </option>
                        <option value="Contact Lens">Contact Lens</option>
                        <option value="Accessories">Accessories</option>
                        <option value="Reading Glasses">Reading Glasses</option>
                      </select>
                      {Errors ? (
                        Errors.category ? (
                          <span className="form__form-group-error">
                            {Errors.category}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                {category === 'Accessories' && (
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Accessories Category
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap">
                        <select
                          className="form-select"
                          onChange={e => {
                            setAccessoriesCategory(e.target.value)
                            if (Errors && Errors.accessoriesCategory) {
                              setErrors((prevErrors) => ({ ...prevErrors, accessoriesCategory: '' }));
                            }
                          }}
                          value={accessoriesCategory}
                          aria-label="Default select example"
                        >
                          <option selected>Select The Accessories Category</option>
                          <option value="Contact Lens Case">Contact Lens Case</option>
                          <option value="Spectacle Case">Spectacle Case</option>
                          <option value="Sunglasses Case">Sunglasses Case</option>
                        </select>
                        {Errors ? (
                          Errors.accessoriesCategory ? (
                            <span className="form__form-group-error">
                              {Errors.accessoriesCategory}
                            </span>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {category === 'Contact Lens' && (
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Lens Category
                    </span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-input-wrap">
                        <select
                          className="form-select"
                          onChange={e => {
                            setdisposables(e.target.value)
                            if (Errors && Errors.disposables) {
                              setErrors((prevErrors) => ({ ...prevErrors, disposables: '' }));
                            }
                          }}
                          value={disposables}
                          aria-label="Default select example"
                        >
                          <option selected>Select the disposable type</option>
                          <option value="Colored">Daily</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                        {Errors ? (
                          Errors.disposables ? (
                            <span className="form__form-group-error">
                              {Errors.disposables}
                            </span>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="form__form-group">
                  <span className="form__form-group-label">Frame Type</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          settype(e.target.value)
                          if (Errors && Errors.type) {
                            setErrors((prevErrors) => ({ ...prevErrors, type: '' }));
                          }
                        }}
                        value={type}
                        aria-label="Default select example"
                      >
                        <option selected>Select The Lens Type</option>
                        <option value="Full Rim">Full Rim</option>
                        <option value="Half Rim">Half Rim</option>
                        <option value="Rimless">Rimless</option>
                      </select>
                      {Errors ? (
                        Errors.type ? (
                          <span className="form__form-group-error">
                            {Errors.type}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Frame Shape</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          setshape(e.target.value)
                          if (Errors && Errors.shape) {
                            setErrors((prevErrors) => ({ ...prevErrors, shape: '' }));
                          }
                        }}
                        value={shape}
                        aria-label="Default select example"
                      >
                        <option selected>Select The Lens Shape</option>
                        <option value="Rectangle">Rectangle</option>
                        <option value="Round">Round</option>
                        <option value="Cat Eye">Cat Eye</option>
                        <option value="Square">Square</option>
                        <option value="Aviator">Aviator</option>
                        <option value="Wayfarer">Wayfarer</option>
                      </select>
                      {Errors ? (
                        Errors.shape ? (
                          <span className="form__form-group-error">
                            {Errors.shape}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Gender</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          setgender(e.target.value)
                          if (Errors && Errors.gender) {
                            setErrors((prevErrors) => ({ ...prevErrors, gender: '' }));
                          }
                        }}
                        value={gender}
                        aria-label="Default select example"
                      >
                        <option selected>Select The Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Unisex">Unisex</option>
                        <option value="kids">Kids</option>
                        <option value="Other">Other</option>
                      </select>
                      {Errors ? (
                        Errors.gender ? (
                          <span className="form__form-group-error">
                            {Errors.gender}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Lens Size</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <select
                        className="form-select"
                        onChange={e => {
                          setsize(e.target.value)
                          if (Errors && Errors.size) {
                            setErrors((prevErrors) => ({ ...prevErrors, size: '' }));
                          }
                        }}
                        value={size}
                        aria-label="Default select example"
                      >
                        <option selected>Select The Lens Size</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                      </select>
                      {Errors ? (
                        Errors.size ? (
                          <span className="form__form-group-error">
                            {Errors.size}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Select Colors</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <div>
                        <FormControl sx={{ width: 1034 }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={color}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={selected => selected.join(', ')}
                            MenuProps={MenuProps}
                          >
                            {names.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox checked={color.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {Errors.color && <span className="form__form-group-error">{Errors.color}</span>}
                    </div>
                  </div>
                </div>
                {color
                  && color.length > 0
                  && color.map(e => (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Add Image for {e} Lens
                      </span>
                      <div className="form__form-group-field">
                        {colorArr
                          && colorArr.length > 0
                          && colorArr.filter(arr => arr.color == e).length > 0
                          ? [...Array(4).keys()].map((a, postion) => {
                            sessionStorage.setItem('postion', postion + 1);

                            return colorArr.filter(arr => arr.color == e)
                              .length > 0 ? (
                              colorArr
                                .filter(arr => arr.color == e)[0]
                                .images.filter((e) => {
                                  if (
                                    e.postion
                                    == sessionStorage.getItem('postion')
                                  ) {
                                    return e;
                                  }
                                }).length > 0 ? (
                                <>
                                  {' '}
                                  <img
                                    src={
                                      colorArr
                                        .filter(arr => arr.color == e)[0]
                                        .images.filter((e) => {
                                          if (
                                            e.postion
                                            == sessionStorage.getItem(
                                              'postion',
                                            )
                                          ) {
                                            return e;
                                          }
                                        })[0].image
                                    }
                                    style={{
                                      height: '150px',
                                      width: '200px',
                                      margin: '0px 15px',
                                    }}
                                  />{' '}
                                  <span
                                    data-color={e}
                                    data-image={
                                      colorArr
                                        .filter(arr => arr.color == e)[0]
                                        .images.filter((e) => {
                                          if (
                                            e.postion
                                            == sessionStorage.getItem(
                                              'postion',
                                            )
                                          ) {
                                            return e;
                                          }
                                        })[0].image
                                    }
                                    style={{
                                      color: 'red',
                                      cursor: 'pointer',
                                    }}
                                    onClick={e => RemoveImage(e)}
                                  >
                                    &#x2717;
                                  </span>
                                </>
                              ) : (
                                <Tooltip
                                  title={
                                    postion + 1 == 1
                                      ? 'Upload Front Image Here'
                                      : postion + 1 == 2
                                        ? 'Upload Right Image Here'
                                        : postion + 1 == 3
                                          ? 'Upload Left Image Here'
                                          : postion + 1 == 4
                                            ? 'Upload Back Image Here'
                                            : ''
                                  }
                                >
                                  <input
                                    type="file"
                                    name={e}
                                    onChange={e => UploadFileAndGetUrl(e, postion + 1)
                                    }
                                    required
                                  />
                                </Tooltip>
                              )
                            ) : (
                              <Tooltip
                                title={
                                  postion + 1 == 1
                                    ? 'Upload Front Image Here'
                                    : postion + 1 == 2
                                      ? 'Upload Right Image Here'
                                      : postion + 1 == 3
                                        ? 'Upload Left Image Here'
                                        : postion + 1 == 4
                                          ? 'Upload Back Image Here'
                                          : ''
                                }
                              >
                                <input
                                  type="file"
                                  name={e}
                                  required
                                  onChange={e => UploadFileAndGetUrl(e, postion + 1)
                                  }
                                />{' '}
                              </Tooltip>
                            );
                          })
                          : [...Array(4).keys()].map((a, postion) => (
                            <Tooltip
                              title={
                                postion + 1 == 1
                                  ? 'Upload Front Image Here'
                                  : postion + 1 == 2
                                    ? 'Upload Right Image Here'
                                    : postion + 1 == 3
                                      ? 'Upload Left Image Here'
                                      : postion + 1 == 4
                                        ? 'Upload Back Image Here'
                                        : ''
                              }
                            >
                              <input
                                type="file"
                                name={e}
                                onChange={e => UploadFileAndGetUrl(e, postion + 1)
                                }
                              />
                            </Tooltip>
                          ))}
                      </div>
                       {Errors[e] && (
                      <div className="form__form-group-error">
                        {Errors[e]}
                      </div>
                    )}
                    </div>
                  ))}

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Add Cover Image
                  </span>
                  <div className="form__form-group-field">
                    {imgCollection ? (
                      <>
                        {' '}
                        <img
                          src={imgCollection}
                          style={{
                            height: '150px',
                            width: '200px',
                            margin: '0px 15px',
                          }}
                        />{' '}
                        <span
                          style={{
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={() => setimgCollection('')}
                        >
                          &#x2717;
                        </span>{' '}
                      </>
                    ) : (
                      <>
                        <input type="file" onChange={e => {
                          CoverPic(e)
                          if (Errors && Errors.imgCollection) {
                            setErrors((prevErrors) => ({ ...prevErrors, imgCollection: '' }));
                          }
                        }

                        } />
                        <div></div>
                      </>
                    )}
                  </div>
                  {Errors ? (
                          Errors.imgCollection ? (
                            <span className="form__form-group-error">
                              {Errors.imgCollection}
                            </span>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}{' '}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Is Trending</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <Checkbox
                        checked={isTrending}
                        onChange={e => setisTrending(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Spinner style={{ color: '#4ce1b6' }} />
      )}{' '}
    </Row>
  );
};

export default reduxForm({
  form: 'subscription_form_validation', 
  enableReinitialize: true,
  validate,
})(HorizontalForm);
