import React from 'react';
import NavBar from '../SelectOptionPage/components/navbar';
import Footer from '../SelectOptionPage/components/footer';

const Services = (props) => {
    const handleClick = (service) => {
        props.history.push('/selectOption', {
            service: service
        })
    }
    return (
        <div className="container">
            <NavBar/>
            <div className="container-fluid bg_img">
                <div className="row">
                    <div className="col-md-4 p-5 service-left-side">
                        <h2>{props.location.state.service.name}</h2>
                        <img src={props.location.state.service.image} alt="" />
                    </div>
                    <div className="col-md-7 service-right-side">
                        <p>{props.location.state.service.title}</p>
                        <span>{props.location.state.service.description}</span>
                        <button onClick={() => handleClick(props.location.state.service)} className="service-book">Book Now</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Services;