import React, { useState,useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import axios from '../../../containers/axios'

// const notifications = [
//   {
//     id: 0,
//     ava: `${process.env.PUBLIC_URL}/img/topbar/ava.png`,
//     name: 'Cristopher Changer',
//     message: ' has started a new project',
//     date: '09:02',
//   },
//   {
//     id: 1,
//     ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
//     name: 'Sveta Narry',
//     message: ' has closed a project',
//     date: '09:00',
//   },
//   {
//     id: 2,
//     ava: `${process.env.PUBLIC_URL}/img/topbar/ava3.png`,
//     name: 'Lory McQueen',
//     message: ' has started a new project as a Project Managert',
//     date: '08:43',
//   },
//   {
//     id: 3,
//     ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
//     name: 'Cristopher Changer',
//     message: ' has closed a project',
//     date: '08:43',
//   },
// ];
let i;
const TopbarNotification = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [notifications, setData] = React.useState([])

  useEffect(() => {
    userdata();
  }, []);

  const userdata = () => {
    axios
      .get("contact-us/")
      .then((res) => {
        console.log("contact-us", res.data);
        setData(res.data.data)

      });
  }

  const toggleNotification = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    
    <div className="topbar__collapse">
      <button className="topbar__btn  topbar__btn--new" type="button " onClick={toggleNotification}>
        <NotificationsIcon />
        <div className="topbar__btn-new-label">
          <div />
        </div>
        {console.log("notifications",notifications)}
      </button>
      {isCollapsed && <button className="topbar__back" type="button" onClick={toggleNotification} />}
      <Collapse
        isOpen={isCollapsed}
        className="topbar__collapse-content"
      >
        <div className="topbar__collapse-title-wrap">
          <p className="topbar__collapse-title">Notifications</p>
          {/* <button className="topbar__collapse-button" type="button">Mark all as read</button> */}
        </div>

        {
        i = 0,
        notifications.length > 0 ? notifications.map(notification => (
          <div className="topbar__collapse-item " key={i++}>
            <div className="topbar__collapse-img-wrap">
              <img className="topbar__collapse-img" src={notification.created_by.image} alt="" />
            </div>
            <p className="topbar__collapse-message">
              <span className="topbar__collapse-name">{notification.name}  </span>
              has query about <b>{notification.subject}</b>
            </p>
            <p className="topbar__collapse-date">16.09</p>
          </div>
        )):""}
        <Link className="topbar__collapse-link" to="/todo" onClick={toggleNotification}>
          See all notifications
        </Link>
      </Collapse>
    </div>
  );
};

export default TopbarNotification;
