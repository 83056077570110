/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
  Card,
  CardBody,
  Col,
  Button,
  ButtonToolbar,
  Row,
  Spinner,
} from 'reactstrap';

import { useHistory } from 'react-router';
import validate from './validate';
import { allFieldsValidation } from '../../../../utils/helpers';
import api from '../../../../api';

dayjs.extend(utc);
dayjs.extend(timezone);

const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} placeholder={placeholder} type={type} />
    {touched && error && (
      <span className="form__form-group-error">{error}</span>
    )}
  </div>
);

renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

const HorizontalForm = ({
  handleSubmit,
  pristine,
  reset,
  submitting,
  props,
}) => {
  const [color, setcolor] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setcolor(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const [Loading, setLoading] = useState(false);
  const [name, setname] = useState('');
  const [address, setaddress] = useState('');
  const [phone_number, setphone_number] = useState('');
  const [latitude, setlatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const [open_time_, setopen_time] = useState('');
  const [close_time_, setclose_time] = useState('');
  const [link, setLink] = useState('');

  function openTimeFun(val) {
    setopen_time(val.target.value);
  }
  function closeTimeFUn(val) {
    setclose_time(val.target.value);
  }

  const [Errors, setErrors] = useState('');
  const histroy = useHistory();
  async function AddStoreAPI(e) {
    e.preventDefault();

    const data = {
      name,
      address,
      phone_number,
      latitude,
      longitude,
      open_time_,
      close_time_,
      link,
    };
    const rules = {
      name: 'required',
      address: 'required',
      phone_number: 'required',
      latitude: ['required'],
      longitude: ['required'],
      open_time_: 'required',
      close_time_: 'required',
    };

    const { isValid, errors } = allFieldsValidation(data, rules);
    if (errors) {
      setErrors(errors);
      return;
    }


    const phoneRegex = /^[0-9]{10}$/;
  if (!phoneRegex.test(phone_number)) {
    toast.error('Phone number is not valid. It should be a 10-digit number without any special characters.', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  }

    if (latitude < -90 || latitude > 90) {
      toast.error('Latitude must be between -180 and 180 degrees inclusive.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } if (longitude < -180 || longitude > 180) {
      toast.error('Longitude must be between -180 and 180 degrees inclusive.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    setLoading(true);
    try {
      api.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem('admin-token-lens')}`;
        return config;
      });
      let open_date = new Date();
      open_date.setHours(parseInt(open_time_.split(':')[0]), parseInt(open_time_.split(':')[1]), 0)

      let close_date = new Date();
      close_date.setHours(parseInt(close_time_.split(':')[0]), parseInt(close_time_.split(':')[1]), 0)


      const result = await api.post('admin/add/Store', {
        ...data,
        open_time: open_date,
        close_time: close_date,
      });

      if (result.data.success) {
        histroy.push('/tables/stores');
      } else {
        setLoading(false);

        toast.error('Please Re - Check The Form And Try Again', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setLoading(false);

      toast.error('Server Error Please Try Again', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <>
      {Loading ? (
        <Row
          style={{
            width: '100%',
            height: '60vh',
            flex: 1,
            marginLeft: 1,
            justifyContent: 'center',
          }}
        >
          <Spinner style={{ color: '#4ce1b6' }} />
        </Row>
      ) : (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Add New Store</h5>
              </div>
              <form className="form form--horizontal" onSubmit={AddStoreAPI}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Name</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Name Of The Store"
                        value={name}
                        onChange={e => setname(e.target.value)}
                        name="name"
                      />

                      {Errors ? (
                        Errors.name ? (
                          <span className="form__form-group-error">
                            {Errors.name[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Phone Number</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        type="number"
                        placeholder="Enter Phone Number"
                        value={phone_number}
                        onChange={e => setphone_number(e.target.value)}
                        name="name"

                      />

                      {Errors ? (
                        Errors.phone_number ? (
                          <span className="form__form-group-error">
                            {Errors.phone_number[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Address</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Address Of The Store"
                        value={address}
                        onChange={e => setaddress(e.target.value)}
                        name="name"
                      />

                      {Errors ? (
                        Errors.address ? (
                          <span className="form__form-group-error">
                            {Errors.address[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Store Link</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Store Link"
                        value={link}
                        onChange={e => setLink(e.target.value)}
                        name="link"
                      />

                      {Errors ? (
                        Errors.link ? (
                          <span className="form__form-group-error">
                            {Errors.link[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Latitude</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter The Latitude Of The Store"
                        value={latitude}
                        onChange={e => setlatitude(e.target.value)}
                        name="name"
                        type="number"
                      />

                      {Errors ? (
                        Errors.latitude ? (
                          <span className="form__form-group-error">
                            {Errors.latitude[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Longitude</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                      <input
                        placeholder="Enter Longitude Of The Store"
                        value={longitude}
                        onChange={e => setlongitude(e.target.value)}
                        name="name"
                        type="number"
                      />

                      {Errors ? (
                        Errors.longitude ? (
                          <span className="form__form-group-error">
                            {Errors.longitude[0]}
                          </span>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Open Time</span>
                  <div className="form__form-group-field">
                    {/* <div className="form__form-group-input-wrap"> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                    <Stack spacing={3}>
                      <input type="time" id="open_time_" name="appt"
                        value={open_time_}
                        onChange={openTimeFun}
                      />
                      {/* <TimePicker
                          // label="Time"
                          value={open_time_}
                          onChange={openTimeFun}
                          renderInput={params => <TextField {...params} />}
                        /> */}
                    </Stack>
                    {/* </LocalizationProvider> */}
                    {Errors ? (
                      Errors.open_time_ ? (
                        <span className="form__form-group-error">
                          {Errors.open_time_[0]}
                        </span>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  {/* </div> */}
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Close Time</span>
                  <div className="form__form-group-field">
                    {/* <div className="form__form-group-input-wrap"> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                    <Stack spacing={3}>
                      <input type="time" id="appt" name="appt"
                        value={close_time_}
                        onChange={closeTimeFUn}
                      />
                      {/* <TimePicker defaultValue={dayjs.utc('2022-04-17T15:30')} /> */}
                      {/* <TimePicker
                          // label="Time"
                          value={dayjs(close_time_)}
                          onChange={closeTimeFUn}
                          renderInput={params => <TextField {...params} />}
                        /> */}
                    </Stack>
                    {/* </LocalizationProvider> */}
                    {Errors ? (
                      Errors.close_time_ ? (
                        <span className="form__form-group-error">
                          {Errors.close_time_[0]}
                        </span>
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  {/* </div> */}
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    onClick={reset}
                    disabled={pristine || submitting}
                  >
                    Cancel
                  </Button>
                </ButtonToolbar>
              </form>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

HorizontalForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'subscription_form_validation', // a unique identifier for this form
  enableReinitialize: true,
  validate,
})(HorizontalForm);
