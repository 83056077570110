import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import moment from "moment";
import TablePagination from '@mui/material/TablePagination';
import useTablePaginator from '../hooks/useTablePaginator';


export default function BasicTable({data , DeleteStore , histroy }) {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePaginator();
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S. No</TableCell>
            {/* <TableCell align="right">Image</TableCell> */}
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            <TableCell align="right">Open Time</TableCell>
            <TableCell align="right">Close Time</TableCell>
            <TableCell align="right">Latitude</TableCell>
            <TableCell align="right">Longitude</TableCell>
            <TableCell align="right">Edit</TableCell>
            <TableCell align="right">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
          data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row , index) => {
         return  <TableRow
              key={page * rowsPerPage + index + 1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {page * rowsPerPage + index + 1}
              </TableCell>
              {/* <TableCell align="right">  <img src={row.imgCollection[0]} height="50px"  width="50px" /></TableCell> */}
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.address}</TableCell>
              <TableCell align="right">{row.phone_number}</TableCell>
              <TableCell align="right">{ moment(row.open_time).format("hh:mm:ss a") }</TableCell>
              <TableCell align="right">{moment(row.close_time).format("hh:mm:ss a") }</TableCell>
              <TableCell align="right">{row.location.coordinates[0]}</TableCell>
              <TableCell align="right">{row.location.coordinates[1]}</TableCell>
            
              <TableCell align="right"><Button variant="contained" onClick={ ()=>histroy.push(`/forms/edit/store/${row._id}`) } >Edit</Button></TableCell>
              <TableCell align="right"><Button variant="contained" onClick={ ()=>DeleteStore(row._id) }>Delete</Button></TableCell>
           
               </TableRow>
})}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}