import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from '../axios';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import NavBar from '../SelectOptionPage/components/navbar';
import Footer from '../SelectOptionPage/components/footer';

const animatedComponents = makeAnimated();

class BookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      bookData: [...this.props.bookingData.map((data) => ({...data, value: data.name, label: data.name}))],
      name: '',
      phone_number: '',
      email: '',
      service: '',
      date: '',
      time: '',
      nextpage: false,
    };
    this.onChange = this.onChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    axios
      .get('services/', {
      })
      .then((res) => {
        this.setState({ data: res.data });
      });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

   handleFormSubmit = (e) => {
     e.preventDefault();
     axios
       .post('http://18.118.148.51:8000/api/book-now/',
         {
           full_name: this.state.name,
           phone_number: this.state.phone_number,
           service: [...this.state.bookData.map((booking) => booking.id)],
           email: this.state.email,
           date: this.state.date,
           time: this.state.time,
         })
       .then( (res) => {
         if (res.data.status === 1) {
           toast("Service Booked Successfully", {
             position: 'top-right',
             autoClose: 3000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
           });
           this.setState({
             nextpage: true,
           });
         }
       });
   }

  handleOnChange = (e) => {
    this.setState({bookData: e});
  }
   

   render() {
     if (this.state.nextpage) {
       return <Redirect to="/" />;
     }
     return (
       <div>
         <div>
           <NavBar />
           <div className="container-fluid bg_img ">
             <div className="row">
               <div className="col-md-6" style={{ paddingTop: '9rem' }}>
                 <h1 className="font_style">Book Your</h1><br /><br /><br /><br />
                 <h1 className="font_style">Appointment</h1>
               </div>
               <div className="col-md-6 mt-3 mb-3  pl-0 pr-0 m-0">
                 <div className="card text-center mr-3">
                   <div className="card-body pb-0">
                     <form className="form_style" onSubmit={this.handleFormSubmit}>
                       <div className="form-group">
                         <input
                           type="text"
                           className="form-control"
                           id="exampleInputEmail1"
                           aria-describedby="emailHelp"
                           name="name"
                           placeholder="Full Name"
                           value={this.state.name}
                           onChange={this.onChange}
                         />
                       </div>
                       <div className="form-group mt-4">
                         <input
                           type="email"
                           className="form-control"
                           id="exampleInputPassword1"
                           name="email"
                           placeholder="Email"
                           value={this.state.email}
                           onChange={this.onChange}
                         />
                       </div>
                       <div className="form-group mt-4">
                         <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="phone_number" value={this.state.phone_number} onChange={this.onChange} placeholder="Phone Number (Prefred Whatsapp for notification)" />
                       </div>
                       <div className="form-group mt-4">
                         <Select
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          defaultValue={this.state.bookData}
                          isMulti
                          isClearable={false}
                          options={this.state.bookData}
                          onChange={(e) => this.handleOnChange(e)}
                         />
                       </div>
                       <div className="form-row mt-4">
                         <div className="form-group col-md-6 ">
                           <input
                             type="date"
                             className="form-control"
                             placeholder="Select Date"
                             name="date"
                             value={this.state.date}
                             onChange={this.onChange}
                           />
                         </div>
                         <div className="form-group col-md-6">
                           <input
                             type="time"
                             className="form-control"
                             placeholder="Select Date"
                             name="time"
                             value={this.state.time}
                             onChange={this.onChange}
                           />
                         </div>
                       </div>
                       <button
                         onClick={(e) => {
                           this.handleFormSubmit(e)
                         }}
                         type="submit"
                         className="btn btn_style mt-4 mb-4 "
                       >Book Now<i className="fa fa-arrow-right" style={{ paddingLeft: 10 }} />
                       </button>
                     </form>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <Footer />
         </div>
       </div>
     );
   }
}

BookNow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect(state => ({ theme: state.theme, bookingData: state.bookingData.items }))(BookNow);
